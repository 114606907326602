import React from 'react'
import { connect } from 'react-redux'
import './styles.scss'

import * as actionCreators from '../../store/actions'
import { getMediaBlobUrl } from '../../../va-corejs-v3/api'
import apiEndPoints from '../../../va-corejs-v3/api/apiEndPoints'

const queryString = require('query-string')

export class MediaViewComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      id: null,
      conversion: null,
      blobUrl: null,
      error: false,
    }
  }

  setQueryStringParams = async () => {
    if (typeof window !== 'undefined') {
      const qs = queryString.parse(window.location.search)

      if (!qs.id) {
        this.setState({
          error: true,
        })
        return
      }

      this.setState({
        id: qs.id,
        conversion: qs.conversion ?? null,
      })
    }
  }

  setBlobUrl = async () => {
    const { id, conversion } = this.state

    const baseApiUrl = process.env.apiBaseUrl ?? '/'
    const path = apiEndPoints.view_media.replace('{id}', id).replace('{conversion}', conversion ?? '')
    const fullUrl = baseApiUrl + path
    const blobUrl = await getMediaBlobUrl(fullUrl)

    if (!blobUrl) {
      this.setState({
        error: true,
      })
      return
    }

    this.setState({
      blobUrl,
    })
  }

  componentDidMount = async () => {
    const { device } = this.props
    await this.setQueryStringParams()

    const { id } = this.state
    if (id && device.isOnline) {
      await this.setBlobUrl()
    }
  }

  render() {
    const { texts } = this.props
    const { id, blobUrl, error } = this.state

    if (!error && (!id || !blobUrl)) {
      return null
    }

    const innerHtml = error ? <p>{texts.media_not_found}</p> : <img className="media-view-image" src={blobUrl} alt="" />

    return (
      <div className="media-view-main">
        <div className="media-view-image-wrapper">{innerHtml}</div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(MediaViewComponent)
