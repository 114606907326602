import React from 'react'
import { connect } from 'react-redux'

import Layout from 'layout'
import MediaViewComponent from '../components/media_view'
import * as actionCreators from '../store/actions'

/*-----------------------------------------------------*/
// Render Report
/*-----------------------------------------------------*/

export class MediaPage extends React.Component {
  render() {
    return (
      <Layout>
        <MediaViewComponent />
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(MediaPage)
